import { render, staticRenderFns } from "./iconStar.vue?vue&type=template&id=7066817c&scoped=true&"
import script from "./iconStar.vue?vue&type=script&lang=js&"
export * from "./iconStar.vue?vue&type=script&lang=js&"
import style0 from "./iconStar.vue?vue&type=style&index=0&id=7066817c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7066817c",
  null
  
)

export default component.exports